<template>
<transition name="fade">
    <div class="disp-modal pp-modal" id="pp-modal">
        <div class="container">
            <div class="payment-modal-wrap">
                <div class="modal-overlay black" @click="togglePaymentPopup()"></div>
                <div :class="['modal-content-wrap', 'payment-modal-body', 'payment_m_b', curentTarifIndexClass]">
                    <div class="payment_popup_close cross-absolute" @click="togglePaymentPopup()" id="close_lead_modal" @keyup.esc="togglePaymentPopup()" @keyup.enter="togglePaymentPopup()">
                    </div>
                    <h3>{{ $t('paymentSwitchToProHeader') }}</h3>
                    <div class="payment_m_b_scroll">
                        <perfect-scrollbar>
                            <div class="payment_m_b_description_wrap">
                                <p class="payment_m_b_description">{{ $t('paymentSwitchToProDescription') }}</p>
                                <!--<div v-if="tarifsData[curentTarifIndex].tarifVariants.length != 1 && tarifsData[curentTarifIndex].validity_months != 1" class="save_current_discount">
                                    <span>{{ $t("saveMoney") + " $" + currentSavingsValue }}</span>
                                </div>-->
                                <button class="save_current_discount" @click="showInfinityTarifs()">
                                    <span>{{ $t("saveMoney") + " -" + largestSavingsPercent + "%" }}</span>
                                </button>
                            </div>
                            <div class="tariff_comparison_wrap">
                                <div class="tariff_comparison_item tariff_comparison_item_current">
                                    <div class="tariff_comparison_header">
                                        <label class="payment_plan_name_label">{{ $t('paymentYourCurrentPlan') }}</label>
                                        <h3 class="payment_plan_name">{{ curentActiveTarif.name }}</h3>
                                    </div>
                                    <div class="tariff_comparison_subject">
                                        <label class="payment_plan_limit_label">{{ $t('paymentPlanLeadsLimit') }}</label>
                                        <p :class="['payment_plan_limit', lemniscateClassCurrent]">{{ curentTariffMaxLeadsCount }}</p>
                                    </div>
                                    <div v-if="curentActiveTarif.price != 0" class="tariff_comparison_subject">
                                        <label class="payment_plan_price_label">{{ $t('paymentPlanPrice') }}</label>
                                        <p class="payment_plan_price">${{ curentActiveTarif.price / curentActiveTarif.validity_months }}</p>
                                    </div>
                                    <div v-if="curentActiveTarif.price != 0" class="tariff_comparison_subject">
                                        <label class="payment_plan_end_data_label">{{ $t('paymentPlanEnd') }}</label>
                                        <p class="payment_plan_end_data">
                                            {{
                                                new Intl.DateTimeFormat(
                                                    locale_lang, 
                                                    { year: 'numeric', month: 'numeric', day: 'numeric' }
                                                )
                                                .format(
                                                    new Date((curentActiveTarif.end_at) * 1000)
                                                )
                                            }}
                                        </p>
                                    </div>
                                    <div class="tariff_comparison_subject">
                                        <label class="payment_plan_leads_left_label">{{ $t('paymentPlanLeadsLeft') }}</label>
                                        <template v-if="allTariffsInactive">
                                            <p class="all_tarifs_inactive">{{ $t('paymentAllTarifInactive') }}</p>
                                        </template>
                                        <template v-else>
                                            <p v-if="curentActiveTarif.name != 'Agency'" class="payment_plan_leads_left">
                                                {{ curentActiveTarif.maxLeadsCount - curentActiveTarif.usedLeads + availableLeadsBonusesCounter}}
                                                /
                                                {{ curentActiveTarif.maxLeadsCount }}
                                            </p>
                                            <p v-else :class="['payment_plan_limit', lemniscateClassCurrent]">∞</p>
                                        </template>
                                    </div>
                                </div>
                                <div class="tariff_comparison_item_future">
                                    <div class="tariff_comparison_item">
                                        <div class="tariff_comparison_header">
                                            <label class="payment_plan_name_label">{{ $t('paymentYourFuturePlan') }}</label>
                                            <h3 class="payment_plan_name">{{ tarifsData[curentTarifIndex].name }}</h3>
                                        </div>
                                        <div class="tariff_comparison_subject">
                                            <label class="payment_plan_limit_label">{{ $t('paymentPlanLeadsLimit') }}</label>
                                            <p :class="['payment_plan_limit', lemniscateClassFuture]">{{ futureTariffMaxLeadsCount }}</p>
                                        </div>
                                        <div class="tariff_comparison_subject">
                                            <label class="payment_plan_price_label">{{ $t('paymentPlanPrice') }}</label>
                                            <div v-if="tarifsData[curentTarifIndex].tarifVariants.length == 1" class="payment_plan_prices_wrap">
                                                <p class="payment_plan_price">${{ tarifsData[curentTarifIndex].price / tarifsData[curentTarifIndex].validity_months }}<span>{{ " /" + $t("month", tarifsData[curentTarifIndex].validity_months) }}</span></p>
                                            </div>
                                            <div v-else class="payment_plan_prices_wrap">
                                                <p v-for="(tarifVariant) in tarifsData[curentTarifIndex].tarifVariants" :key="tarifVariant.validity_months" class="payment_plan_price"><span>{{ "$" + tarifVariant.price + " / "+ $t("month", {n: tarifVariant.validity_months} ) }}</span> ({{ "$" + Math.ceil(tarifVariant.price / tarifVariant.validity_months) + "/" + $t("billingMonth") }})</p>
                                            </div>
                                        </div>
                                        <div class="tariff_comparison_subject">
                                            <label class="payment_plan_end_data_label">{{ $t('paymentPlanEnd') }}</label>
                                            <p class="payment_plan_end_data">
                                                {{
                                                    new Intl.DateTimeFormat(
                                                        locale_lang, 
                                                        { year: 'numeric', month: 'numeric', day: 'numeric' }
                                                    )
                                                    .format(futureDateTarif)
                                                }}
                                            </p>
                                        </div>
                                        <!-- <div class="tariff_comparison_subject">
                                            <label class="payment_plan_leads_left_label">{{ $t('paymentPlanLeadsLeft') }}</label>
                                            <p class="payment_plan_leads_left">
                                                {{ tarifsData[curentTarifIndex].maxLeadsCount}}
                                                /
                                                {{ tarifsData[curentTarifIndex].maxLeadsCount }}
                                            </p>
                                        </div> -->
                                    </div>
                                    <div v-if="tarifsData[curentTarifIndex].tarifVariants.length != 1" class="tariff_comparisom_discount_wrap">
                                        <!-- <p>{{ $t("discount") + " $" + currentSavingsValue}}</p> -->
                                        <p>{{ $t("discount") + " $" + maxSavingsValue}}</p>
                                    </div>
                                </div>
                            </div>
                            <transition name="height-tarif-period-select">
                                <div v-show="tarifsData[curentTarifIndex].tarifVariants.length != 1" class="tarifs_period_wrap">
                                    <label class="select_default_label" for="tarif_validity">{{ $t('billingSelectPeriod') }}</label>
                                    <select class="select_default tarif_validity_select" name="tarif_validity" id="tarif_validity" v-model="validityMonths">
                                        <option v-for="tarifVariant in tarifsData[curentTarifIndex].tarifVariants" :key="tarifVariant.validity_months" :value="tarifVariant.validity_months">{{ $tc("month", tarifVariant.validity_months) }}</option>
                                    </select>
                                </div>
                            </transition>
                            <div class="tarifs_input_wrap">
                                <VueSlider v-model="selectedTarif" :tooltip-formatter="'$' + Math.ceil(tarifsData[curentTarifIndex].price / tarifsData[curentTarifIndex].validity_months) + '/' + $t('billingTariffMonth')" tooltip="always" contained="false" min="0" :marks="marksRangeTarif" :data="dataRangeTarif" @change="(v) => { eventValue = v}" />
                            </div>
                            <p class="tarifs_description">
                                {{ $t('billingChangePlanDescription') }}
                            </p>
                            <p class="tarifs_description_important">
                                <span><b>{{ $t('billingChangePlanDescriptionImportant') }}</b></span> {{ $t('billingChangePlanDescriptionImportantMonth') }}
                            </p>
                            <div class="tarifs_actions">
                                <template v-if="curentTarifIndex < tarifsLength">
                                    <template v-if="isShowPayButton">
                                        <form v-bind="tarifsData[curentTarifIndex].payButton.form">
                                            <input v-for="(value, key) in tarifsData[curentTarifIndex].payButton.hidden" :key="key" :name="key" type="hidden" :value="value">
                                            <template v-if="tarifsData[curentTarifIndex].payButton && Object.keys(tarifsData[curentTarifIndex].payButton.inputs).length > 0">
                                                <div v-for="(value, key) in tarifsData[curentTarifIndex].payButton.inputs" :key="key" class="default_checkbox_wrap">
                                                    <label class="default_checkbox_text subscription_tarif_checkbox" :style="justifyContentForCheckbox">
                                                        <input :name="key" v-bind="value">
                                                        <span class="checkbox_mark"></span>
                                                        {{ $t("subscriptionTarifLable") }} 
                                                    </label>
                                                </div>
                                            </template>
                                            <input class="btn-default-blue button_pay_for_tarif" type="submit" target="_blank" :value="$t('billingChangePlanPay')" @keyup.esc="togglePaymentPopup()">
                                        </form>
                                    </template>
                                </template>
                            </div>
                        </perfect-scrollbar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
import axios from 'axios'
import VueSlider from 'vue-3-slider-component'
export default {
    components: {
        VueSlider
    },
    data() {
        return {
            baseBackendUrl: process.env.VUE_APP_BASE_URL,
            baseUserIdent: process.env.VUE_APP_USER_IDENT,
            selectedTarif: 300,
            value: 0,
            eventValue: 0,
            userInfo: {
                id: "17",
                email: "vasyayaya@mail.ru",
                name: "Jhonathan",
                role: "user",
                profile: "agency",
                tarifs: [{
                    id: "1",
                    name: "Free.",
                    price: 0,
                    usedLeads: 0,
                    maxLeadsCount: 100,
                    maxQuizzesCount: 0,
                    validity_months: 1,
                    created_at: 1670528835,
                    is_active: true,
                    start_at: 1670528835,
                    end_at: 1670528835,
                }],
                tarif: {},
                period_starts_at: null,
                payments: [{
                    id: "6",
                    payment: "PT172022212_1670528631",
                    amount: 1,
                    created_at: 1670528835,
                    payment_system: "Stripe",
                    data: "{\"cardType\": \"Visa\"}",
                }],
                created_at: 1665096088,
                quizCounter: 0,
                leadCounter: 0,
                isBlocked: false,
                bonuses: [{
                    start: 1665096088,
                    end: 1665096088,
                    leads_count: 0,
                    used: 0,
                    coupon: '',
                    created_at: 1665096088,
                    updated_at: 1665096088,
                }],
            },
            payment: {
                form: {
                    method: "POST",
                    action: "https://pre.adsquiz.io/pay",
                    "accept-charset": "UTF-8"
                },
                hidden: {
                    tarif_id: 1,
                },
                inputs: {
                    is_subscription: {
                        type: "checkbox",
                        value: true
                    },
                }
            },
            tarifsData: [{
                    id: "1",
                    maxLeadsCount: 100,
                    maxQuizzesCount: 0,
                    name: "Pro.",
                    payButton: null,
                    price: 0,
                    validity_months: 1,
                    tarifVariants: [
                        {
                            id: "1",
                            maxLeadsCount: 100,
                            maxQuizzesCount: 0,
                            name: "Pro.",
                            payButton: null,
                            price: 0,
                            validity_months: 1,
                        }
                    ]
                },
                {
                    id: "2",
                    maxLeadsCount: 300,
                    maxQuizzesCount: 0,
                    name: "Pro.",
                    payButton: {
                        form: {
                            method: "POST",
                            action: "https://pre.adsquiz.io/pay",
                        },
                        hidden: {
                            tarif_id: 1,
                        },
                        inputs: {
                            is_subscription: {
                                type: "checkbox",
                                value: true
                            },
                        }
                    },
                    price: 20,
                    validity_months: 1,
                    tarifVariants: [
                        {
                            id: "2",
                            maxLeadsCount: 300,
                            maxQuizzesCount: 0,
                            name: "Pro.",
                            payButton: {
                                form: {
                                    method: "POST",
                                    action: "https://pre.adsquiz.io/pay",
                                },
                                hidden: {
                                    tarif_id: 1,
                                },
                                inputs: {
                                    is_subscription: {
                                        type: "checkbox",
                                        value: true
                                    },
                                }
                            },
                            price: 20,
                            validity_months: 1,
                        }
                    ]
                },
            ],
            dataRangeTarif: [{
                    value: 100,
                    label: '0'
                },
                {
                    value: 300,
                    label: '20'
                },
                {
                    value: 500,
                    label: '30'
                },
                {
                    value: 1000,
                    label: '40'
                },
                {
                    value: 2000,
                    label: '50'
                },
                {
                    value: 3500,
                    label: '60'
                },
                {
                    value: 5000,
                    label: '50'
                },
            ],
            marksRangeTarif: {
                '50' : {label: '50'},
                '100' : {label: '100'},
                '300' : {label: '300'},
                '500' : {label: '500'},
                '1000' : {label: '1000'},
                '2000' : {label: '2000'},
                '5000' : {label: '5000'},
                '100000' : {label: '∞', labelStyle: {fontSize: '32px'}},
            },
            validityMonths: 1,
        };
    },
    computed: {
        locale_lang() {
            if (this.$i18n.locale == "UA") {
                return "UK"
            } else {
                return this.$i18n.locale
            }
        },
        priceInMonth() {
            return this.tarifsData[this.curentTarifIndex].price / this.tarifsData[this.curentTarifIndex].validity_months
        },
        futureDateTarif() {
            // Получение текущей даты
            const today = new Date();

            // Добавление 30 дней к текущей дате
            const futureDate = new Date(today);
            futureDate.setDate(today.getDate() + 30);
            return futureDate
        },
        isShowPayButton() {
            if (this.tarifsData[this.curentTarifIndex].payButton) {
                return true
            } else {
                return false
            }
        },
        tarifsLength() {
            return this.tarifsData.length
        },
        curentTarifIndex() {
            return this.tarifsData.findIndex((element) => element.maxLeadsCount == this.selectedTarif)
        },
        curentActiveTarif() {
            let lastActiveTariff = null;
            let activeTarifExists = false;
            let lastTarif = null;

            for (const tarif of this.userInfo.tarifs) {
                if (tarif !== null) {
                    if (tarif.is_active) {
                        if (!lastActiveTariff || tarif.created_at > lastActiveTariff.created_at) {
                            lastActiveTariff = tarif;
                            activeTarifExists = true;
                        }
                    }
                    lastTarif = tarif;
                }
            }
            if (!activeTarifExists && lastTarif) {
                return lastTarif;
            } else return lastActiveTariff
        },
        allTariffsInactive() {
            if (this.curentActiveTarif.is_active) {
                return false
            } else return true
        },
        // availableLeadsCounter () {
        //  Это работает, и показывает все доступные лиды
        //     let availableleads = 0;
  
        //     for (const tarif of this.userInfo.tarifs) {
        //         if (tarif.is_active) {
        //             availableleads += tarif.maxLeadsCount - tarif.usedLeads;
        //         }
        //     }
        //     if (this.userInfo.bonuses.length) {
        //         let bonuses_leads = 0
        //         this.userInfo.bonuses.forEach((bonus) => {
        //             bonuses_leads += bonus.leads_count - bonus.used
        //         })
        //         return (availableleads + bonuses_leads)
        //     } else {
        //         return availableleads
        //     }
        // },
        availableLeadsBonusesCounter () {
            let bonuses_leads = 0
            if (this.userInfo.bonuses.length) {
                this.userInfo.bonuses.forEach((bonus) => {
                    bonuses_leads += bonus.leads_count - bonus.used
                })
            }
            return bonuses_leads
        },
        curentTariffMaxLeadsCount () {
            if (this.curentActiveTarif.name != 'Agency') {
                return this.curentActiveTarif.maxLeadsCount
            } else {
                return "∞"
            }
        },
        futureTariffMaxLeadsCount () {
            if (this.tarifsData[this.curentTarifIndex].name != 'Agency') {
                return this.tarifsData[this.curentTarifIndex].maxLeadsCount
            } else {
                return "∞"
            }
        },
        lemniscateClassCurrent () {
            if (this.curentActiveTarif.name == 'Agency') {
                return "payment_plan_limit_lemniscate"
            } else {
                return ""
            }
        },
        lemniscateClassFuture () {
            if (this.tarifsData[this.curentTarifIndex].name == 'Agency') {
                return "payment_plan_limit_lemniscate"
            } else {
                return ""
            }
        },
        curentTarifIndexClass () {
            if (this.tarifsData[this.curentTarifIndex].tarifVariants.length != 1) {
                return "count_of_periods_" + this.tarifsData[this.curentTarifIndex].tarifVariants.length
            } else {
                return "count_of_periods_1"
            }
        },
        currentSavingsValue() {

            // Этот компьютед считает текущую экономию, между выбранным тарифом и обычным на 1 месяц.
            const currentTarif = this.tarifsData[this.curentTarifIndex];

            // Найти тариф с самой большой ценой за месяц
            const highestMonthlyPrice = currentTarif.tarifVariants.reduce((maxVariantPrice, variant) => {
                return Math.max(maxVariantPrice, variant.price / variant.validity_months);
            }, -Infinity);
    

            // Вычислить цену текущего тарифа за месяц
            const currentPricePerMonth = currentTarif.price / currentTarif.validity_months;

            // Вычислить процент экономии
            return Math.floor((highestMonthlyPrice - currentPricePerMonth) * currentTarif.validity_months);

        },
        maxSavingsValue() {
            const currentTarif = this.tarifsData[this.curentTarifIndex];

            // Найти тариф с самой маленькой ценой за месяц
            const lowestMonthlyPriceVariant = currentTarif.tarifVariants.reduce((minVariant, variant) => {
                const pricePerMonth = variant.price / variant.validity_months;
                if (pricePerMonth < minVariant.pricePerMonth || minVariant.variant === null) {
                    return {
                        variant: variant,
                        pricePerMonth: pricePerMonth
                    };
                } else {
                    return minVariant;
                }
            }, { variant: null, pricePerMonth: Infinity });

            const lowestMonthlyPrice = lowestMonthlyPriceVariant.pricePerMonth;
            // const lowestMonthlyPriceTariffVariant = lowestMonthlyPriceVariant.variant;

            // Найти тариф с самой большой ценой за месяц
            const highestMonthlyPriceVariant = currentTarif.tarifVariants.reduce((maxVariant, variant) => {
                const pricePerMonth = variant.price / variant.validity_months;
                if (pricePerMonth > maxVariant.pricePerMonth) {
                    return {
                        variant: variant,
                        pricePerMonth: pricePerMonth
                    };
                } else {
                    return maxVariant;
                }
            }, { variant: null, pricePerMonth: -Infinity });

            const highestMonthlyPrice = highestMonthlyPriceVariant.pricePerMonth;
            const lowestMonthlyPriceTariffVariant = lowestMonthlyPriceVariant.variant;
            
            // Вычислить размер экономии
            return Math.floor((highestMonthlyPrice - lowestMonthlyPrice) * lowestMonthlyPriceTariffVariant.validity_months);
        },
        largestSavingsPercent() {
            // Найти самый дорогой и самый дешевый тарифы за месяц
            const agencyTarif = this.tarifsData.find(tarif => tarif.name === "Agency");
            const maxPricePerMonth = agencyTarif.tarifVariants.reduce((maxPrice, variant) => {
                const pricePerMonth = variant.price / variant.validity_months;
                return Math.max(maxPrice, pricePerMonth);
            }, -Infinity);

            const minPricePerMonth = agencyTarif.tarifVariants.reduce((minPrice, variant) => {
                const pricePerMonth = variant.price / variant.validity_months;
                return Math.min(minPrice, pricePerMonth);
            }, Infinity);


            // Вычислить процент экономии
            return Math.floor(((maxPricePerMonth - minPricePerMonth) / maxPricePerMonth) * 100);

        }

    },
    methods: {
        togglePaymentPopup() {
            this.$emit('togle-payment-popup', false);
        },
        showInfinityTarifs() {
            console.log('Functional under development')
            // this.selectedTarif = 100000
        },
        getProfileInfo() {
            // Below first string for query
            // users(` + this.userRequestedIdentificator + `) {
            var data = JSON.stringify({
                query: `query { 
                    users (` + this.baseUserIdent + `) {
                        id
                        email
                        name
                        role
                        profile
                        isBlocked
                        interface_lang
                        tarifs { 
                            id
                            name
                            price
                            usedLeads
                            maxLeadsCount
                            maxQuizzesCount
                            validity_months
                            created_at
                            is_active
                            start_at
                            end_at
                        }

                        period_starts_at

                        payments { 
                            id
                            payment_system
                            payment
                            amount
                            created_at
                            data
                        }
                        created_at
                        quizCounter
                        leadCounter
                        isBlocked
                        bonuses {
                            start
                            end
                            leads_count
                            used
                            coupon
                        }
                    }
                    tarifs {
                        id
                        maxLeadsCount
                        maxQuizzesCount
                        name
                        payButton
                        price
                        validity_months
                    }
                }`,
                variables: {}
            });

            var config = {
                method: 'post',
                url: this.baseBackendUrl + '/graphql',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(response => {
                    this.userInfo = response.data.data.users[0]
                    let tarifs = []
                    this.tarifsData = []
                    tarifs = response.data.data.tarifs
                    tarifs.forEach(function (item, i) {
                        if (item.payButton) {
                            tarifs[i].payButton = JSON.parse(tarifs[i].payButton)
                        } else {
                            tarifs[i].payButton = null
                        }
                    });

                    // Фильтр массива
                    tarifs = tarifs.filter(function (item) {
                        // Проверяем, что кнопка оплаты не равна null
                        if (item.payButton !== null) {
                            // Возвращаем true, чтобы сохранить этот элемент в отфильтрованном массиве
                            return true;
                        }
                        // Если кнопка оплаты равна null, возвращаем false
                        return false;
                    });


                    // Создаем новый массив с преобразованными тарифами
                    const updatedTarifs = tarifs.reduce((accumulator, currentTarif) => {
                        // Ищем в массиве аккумулятора тариф с таким же именем
                        const existingTarif = accumulator.find(tarif => tarif.name === currentTarif.name);
                        
                        // Если такой тариф уже существует, добавляем текущий тариф в его варианты
                        if (existingTarif) {
                            existingTarif.tarifVariants.push(currentTarif);
                        } else {
                            // Если такого тарифа еще нет, добавляем его в массив с вложенным tarifVariants
                            accumulator.push({
                                ...currentTarif,
                                tarifVariants: [currentTarif]
                            });
                        }
                        
                        return accumulator;
                    }, []);

                    this.tarifsData = updatedTarifs
                    this.dataRangeTarif = []
                    const marksRangeTarif = {};
                    this.tarifsData.forEach(item => {
                        if (item.name != "Test") {
                            this.dataRangeTarif.push({
                                value: item.maxLeadsCount,
                                label: item.price,
                            })

                            const obj = { label: item.maxLeadsCount.toString() };
                            if (item.name === "Agency") {
                                obj.label = "∞";
                                obj.labelStyle = { fontSize: '28px!important' };
                            }
                            marksRangeTarif[item.maxLeadsCount.toString()] = obj;
                        }
                    });
                    this.marksRangeTarif = marksRangeTarif
                    this.errorAuth(response.status)
                })
                .catch(error => {
                    console.log(error)
                    this.errorAuth(error.response.status)
                })

        },
    },
    mounted() {
        this.getProfileInfo()
    },
    watch: {
        validityMonths (newVal) {
            this.tarifsData.forEach((tarif) => {
                tarif.tarifVariants.forEach((variant) => {
                    if (variant.validity_months === newVal) {
                        tarif.id = variant.id;
                        tarif.maxLeadsCount = variant.maxLeadsCount;
                        tarif.maxQuizzesCount = variant.maxQuizzesCount;
                        tarif.price = variant.price;
                        tarif.payButton = variant.payButton;
                        tarif.validity_months = variant.validity_months
                    }
                });
            });
        }
    }
}
</script>

<style>
.payment-modal-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.payment-modal-body {
    margin:auto;
    border-radius: 8px;
    width: 624px;
    max-width: 95%;
    padding: 24px;
    height: fit-content;
    max-height: 95%;
}


@media screen and (max-height: 890px) {
    .payment-modal-body.count_of_periods_4 {
        overflow: auto;
    }
}


@media screen and (max-height: 860px) {
    .payment-modal-body.count_of_periods_3 {
        overflow: auto;
    }
}


@media screen and (max-height: 840px) {
    .payment-modal-body.count_of_periods_2 {
        overflow: auto;
    }
}

@media screen and (max-height: 736px) {
    .payment-modal-body.count_of_periods_1 {
        overflow: auto;
    }
}

.payment_m_b_scroll {
    height: calc(100% - 40px);
}

.payment_m_b_scroll> .ps {
    padding-right: 8px;
    margin-right: -8px;
}

.payment_m_b h3 {
    margin-bottom: 8px;
}
.payment_m_b_description_wrap {
    display: flex;
    margin-bottom: 16px;
}
.save_current_discount {
    width: 150px;
    min-width: 150px;
    display: flex;
    justify-content: end;
    align-items: center;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
}
.tariff_comparison_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
}
.tariff_comparison_item {
    width: calc(50% - 12px);
    height: max-content;
    padding: 16px 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.tariff_comparison_item label,
.tariff_comparison_item label:hover {
    cursor: default;
}

.tarifs_period_wrap {
    margin: 24px 0 38px;
    height: 80px;
}

.payment_plan_prices_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    gap: 4px;
}

.payment_plan_prices_wrap p {
    width: 100%;
}

.tarifs_input_wrap .vue-slider-marks {
    display: flex!important;
    justify-content: space-between!important;;
}

.tarifs_input_wrap .vue-slider-mark {
    position: relative!important;;
}

.tarifs_actions form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
}

.tarifs_actions label {
    display: flex;
    flex-direction: row;
}

.tarifs_actions label p {
    display: flex;
    flex-direction: row;
}

.subscription_tarif_checkbox {
    display: flex;
    align-items: inherit;
    margin-bottom: 16px;
}

@media screen and (max-width: 620px) {
    .payment-modal-body {
        padding: 16px;
    }
    .tariff_comparison_item {
        gap: 4px;
        padding: 8px 16px;
    }
}



</style>
